import { FeaturesEnum } from '../../../../config/FeatureFlags/FeaturesEnum';
import { FeatureFlagsConfig } from '../../../../config/FeatureFlags/FeatureFlagsContext';

const FeatureFlags: FeatureFlagsConfig = {
  [FeaturesEnum.Store]: true,
  [FeaturesEnum.Arcade]: false,
  [FeaturesEnum.Streams]: false,
  [FeaturesEnum.Wallet]: false,
  [FeaturesEnum.AllowAddingCurrency]: false,
  [FeaturesEnum.Keycloak]: false,
  [FeaturesEnum.OnboardingEvent]: false,
  [FeaturesEnum.StreamsOnHomePage]: false,
  [FeaturesEnum.UpcomingMatchTimes]: false,
  [FeaturesEnum.SubscriptionsUpgrade]: false,
  [FeaturesEnum.SubscriptionsUnsubscribe]: false,
  [FeaturesEnum.Subscriptions]: false,
  [FeaturesEnum.CheckinIndicators]: false,
  [FeaturesEnum.FeaturedStoreHomepage]: false,
  [FeaturesEnum.StoreNavigation]: true,
  [FeaturesEnum.Events]: false,
  [FeaturesEnum.RemoteLocalizations]: false,
  [FeaturesEnum.HeaderIcons]: false,
  [FeaturesEnum.StoreBrowseByGamesToBottom]: true,
  [FeaturesEnum.StoreBanners]: false,
  [FeaturesEnum.StoreProfile]: true,
  [FeaturesEnum.FooterIcons]: false,
};

export default FeatureFlags;
